/* Let's get this party started */
::-webkit-scrollbar {
    width: 9px;
}

/* Track */
::-webkit-scrollbar-track {

}

/* Handle */
::-webkit-scrollbar-thumb {
    border-left: solid 2px #777;
}

/*button*/
::-webkit-scrollbar-button {
    height: 18px;
}

body {
    font-family: "Microsoft YaHei UI", "PingHei", "STHeitiSC-Light", "Helvetica Neue", "Helvetica", "Arial", sans-serif;
    color: #666;
    /*background: rgb(50,100,145);*/
}

h1, h2, h3, h4, h5, h6, .h1, .h2, .h3, .h4, .h5, .h6 {
    color: #212121;
}

body, .app {
    position: absolute;
    margin: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
    /*background-color: white;*/
}

.bg {
    position: absolute;
    top: -18px;
    left: -18px;
    right: -18px;
    bottom: -18px;
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
    z-index: -1;
}

.bg:after {
    content: ' ';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(10, 10, 10, .075);
    /*mix-blend-mode: darken;*/
}

/*desktop*/

.desktop {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    padding: 180px 60px 0px;
    display: flex;
    justify-content: center;
}

.app-grid-wrapper {
    max-width: 1354px;
    display: flex;
    flex-flow: row wrap;
    align-content: flex-start;

}

.app-grid {
    min-width: 180px;
    min-height: 224px;
    flex: 16.667%;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: space-around;
}

.app-icon {
    position: relative;
    cursor: pointer;
    border-radius: 18%;
    /*background: rgba(255,255,255,.8);*/
    /*background-image: url();*/
    background-position: center center;
    background-size: contain;
    background-repeat: no-repeat;
}

.app-grid > .app-icon {
    width: 146px;
    height: 146px;
    box-shadow: 0px 10px 25px 6px rgba(0, 0, 0, .22);
    transition-duration: .3s;
}

.app-grid > .app-icon:hover {
    box-shadow: 0px 12px 25px 8px rgba(0, 0, 0, .32);
}

/*.app-grid>.app-name{
  text-shadow: 0px 7px 21px rgba(0,0,0,0.8);
  will-change:transform;
}*/

.app-launcher-grid > .app-icon {
    position: absolute;
    top: 10px;
    left: 10px;
    right: 10px;
}

.app-launcher-grid > .app-icon:before {
    content: ' ';
    display: block;
    padding-bottom: 100%;
}

.app-launcher-grid-current > .app-icon {
    border-radius: 0px;
    top: 0px;
    left: 0px;
    right: 0px;
    bottom: 0px;
}

.app-launcher-grid > .app-name {
    position: absolute;
    width: 100%;
    display: block;
    bottom: 1px;
    text-align: center;

}

.app-name {
    color: white;
}

.project-title {
    position: absolute;
    left: 10px;
    top: 6px;
    height: 43px;
    width: 120px;
}

.project-name {
    position: absolute;
    /*width:150px;*/
    height: 43px;
    color: white;
    right: 53px;
    top: 6px;
    line-height: 46px;
    overflow: hidden;
    font-size: 16px;
    z-index: 2;
    pointer-events: none;
}

.project-logo {
    display: inline-block;
    height: 100%;
    width: 100%;
    background-position: left center;
    background-repeat: no-repeat;
    background-size: contain;
}

.avatar {
    position: absolute;
    top: 15px;
    right: 15px;
    border-radius: 15px;
    border: 1px solid rgba(255, 255, 255, .7);
    text-align: center;
    font-size: 24px;
    z-index: 2;
    line-height: 29px;
    cursor: pointer
}

.avatar > .list-group {
    margin-bottom: 0;
    width: 120px;
    font-size: 14px;
    text-align: left;
}

.avatar .list-group-item:nth-last-of-type(1) {
    border-radius: 0px 0px 15px 15px;
    border-bottom: none;
}

/*app*/

.app-frame {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;

}

.app-title {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 112px;
}

.app-title > .app-icon {
    position: absolute;
    left: 10px;
    top: 10px;
    width: 36px;
    height: 36px;
    /*border-radius: 0;*/
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center center;
    /*border: solid 1px rgba(255,255,255,.28);*/
}

.app-title > .app-name {
    position: absolute;
    margin: 0;
    left: 64px;
    line-height: 56px;
    opacity: 1 !important;
}

.app-launcher {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 56px;
    display: flex;
    flex-flow: row;
    justify-content: center;
}

.app-launcher-mask {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 56px;
    opacity: 0;
    cursor: pointer;
}

.app-launcher-mask:hover {
    opacity: 1;
}

.app-launcher-grid {
    position: relative;
    width: 56px;
    height: 56px;
    /*will-change:width;*/
}

.app-launcher-grid-current {
    width: 224px;
}

.app-view {
    position: absolute;
    top: 56px;
    left: 0;
    right: 0;
    bottom: 0;
    padding-top: 48px;
    padding-bottom: 10vh !important;
    background-color: white;
    overflow-y: scroll;
}

.app-view-mask {
    position: absolute;
    top: 56px;
    left: 0;
    right: 0;
    bottom: 0;
    cursor: pointer;

}

/*phase*/
.avatar.phase1 {
    color: rgb(255, 255, 255);
    width: 29px;
    height: 29px;
    overflow: hidden;
    transition-duration: .33s
}

.avatar.phase2 {
    transition-duration: .33s;
    overflow-x: hidden;
    color: rgb(55, 55, 55);
    width: 120px;
    /*height: 100px;*/
    box-shadow: 0px 4px 18px 6px rgba(0, 0, 0, .12);
    background-color: rgba(255, 255, 255, .8)
}

.app.phase1 > .desktop {
    transition-duration: .53s;
    transform: scale(.96);
}

.app.phase2 > .desktop {
    transition-duration: .53s;
}

.app.phase1 > .app-frame {
    transition-duration: .53s;
    transform: scale(.9);
    opacity: 0;
    pointer-events: none;
}

.app.phase2 > .app-frame {
    transition-duration: .53s;
}

.app.phase1 .project-title {
    transition-duration: .53s;
    opacity: 1;
}

.app.phase1 .project-name {
    transition-duration: .53s;
    opacity: 1;
}

.app.phase2 .project-title {
    transition-duration: .53s;
    opacity: 0;
}

.app.phase2 .project-name {
    transition-duration: .53s;
    opacity: 0;
}

.app-frame.phase1 .app-title {
    transition-duration: .33s;
}

.app-frame.phase2 .app-title {
    transition-duration: .33s;
    /*transform: translate(0px,56px); */
    /*transform: translateZ(0px);*/
    opacity: 0;
    /*height: 112px;*/
}

.app-frame.phase1 .app-launcher {
    transition-duration: .33s;
    opacity: .75
}

.app-frame.phase2 .app-launcher {
    transition-duration: .33s;
    height: 112px;
}

.app-frame.phase1 .app-launcher .app-icon {
    transition-duration: .33s;
    /*border: solid 1px rgba(255,255,255,.5) */
}

.app-frame.phase2 .app-launcher .app-icon {
    transition-duration: .33s;
    /*border: solid 1px rgba(255,255,255,0) */
}

.app-frame.phase1 .app-launcher-mask {
    transition-duration: .33s;
}

.app-frame.phase2 .app-launcher-mask {
    transition-duration: .33s;
    pointer-events: none;
    height: 112px;
    opacity: 0;
}

.app-frame.phase1 .app-name {
    transition-duration: 0s;
    opacity: 0;
    transition-delay: 0s;
}

.app-frame.phase2 .app-name {
    transition-duration: .33s;
    opacity: 1;
    transition-delay: .25s;
}

.app-frame.phase1 .app-view {
    transition-duration: .33s;
}

.app-frame.phase2 .app-view {
    transition-duration: .33s;
    transform: translate(0px, 56px);
}

.app-frame.phase1 .app-view-mask {
    transition-duration: .33s;
    pointer-events: none;
    opacity: 0
}

.app-frame.phase2 .app-view-mask {
    transition-duration: .33s;
    transform: translate(0px, 56px);
    pointer-events: inherit;
}

.app-frame.phase1 .app-launcher-grid {
    transition-duration: .33s;
}

.app-frame.phase2 .app-launcher-grid {
    transition-duration: .33s;
    width: 84px;
    height: 112px;
}

/*增加订单提醒数字图标样式*/
.app-icon > .corner {
    position: absolute;
    top: -3%;
    right: -3%;
    text-align: center;
    display: inline-block;
    font-style: normal;
    font-weight: bold;
    border-radius: 100px;
    background-color: red;
    color: rgba(255, 255, 255, 1);
    overflow: hidden;
}

.desktop .app-icon > .corner {
    height: 22%;
    min-width: 22%;
    padding: 0 10px;
    line-height: 32px;
}

.app-launcher .app-icon > .corner {
    transition-duration: .15s
}

.app-frame.phase1 .app-launcher .app-icon > .corner {
    color: transparent;
    width: 8px;
    height: 8px;
    color: rgba(255, 255, 255, 0);
}

.app-frame.phase2 .app-launcher .app-icon > .corner {
    padding: 0 6px;
    height: 24px;
    min-width: 24px;
    line-height: 24px;
    font-size: 12px;
}

/*modify*/
/*bs extend*/
a {
    cursor: pointer
}

table {
    cursor: default;
}

th, .th {
    color: #555;
    font-weight: normal;
}

td {
    color: #818181
}

label {
    /*margin-bottom: 10px;*/
    margin-top: 14px
}

hr {

}

textarea {
    resize: none;
    min-height: 5em
}

textarea.form-control {
    height: 94px;
}

.form-control {
    background-color: transparent;
}

/*.form-control[required]{
    background-color: red
}
.form-control[required]:focus{
    background-color: transparent;
}
.form-control[required]:visited{
    background-color: green;
}*/

.form-control:focus {
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075), 0 0 8px rgba(0, 0, 0, .25);
}

.form-control[required]:placeholder-shown {
    background-color: rgb(255, 221, 128)
}

.container {
    /*max-width: 980px;*/
    padding-left: 180px;
}

.container-fluid.has-nav-left {
    margin-left: 160px;
}

.title-btn {
    font-size: 24px;
    vertical-align: text-top;
}

/*nav-tab*/
.nav > li > a {
    padding: 5px 15px;
}

.nav.nav-tabs {
    margin-bottom: 20px;
}

.tab-content {
    position: relative;
}

.tab-content > .tab-pane {
    position: absolute;
    width: 100%;
}

.nav-tabs > li.active > a, .nav-tabs > li.active > a:focus, .nav-tabs > li.active > a:hover {
    color: #555;
    cursor: default;
    background-color: #fff;
    border: none;
    border-bottom: solid 2px;
}

.nav-tabs > li > a {
    color: #818181
}

/*modal*/
.modal {
    display: block;
    overflow-y: auto;
    pointer-events: none;
}

.modal.fade.in {
    pointer-events: auto;
}

.modal-backdrop {
    z-index: 0;
    pointer-events: none;
    height: 400%;
}

.modal-dialog {
    margin: 72px auto;
}

.modal-content {
    border: none;
    /*-webkit-box-shadow: 0 5px 24px 8px rgba(0,0,0,.125);*/
    /*box-shadow: 0 5px 24px 8px rgba(0,0,0,.125);*/
    -webkit-box-shadow: none;
    box-shadow: none;
    border-radius: 24px;
    /* background-color: rgba(255, 255, 255, .88) */
}

.modal-sm .modal-body {
    padding: 15px;
}

.modal-body {
    padding: 15px 30px;
}

@media print {
    .modal-body {
        /* margin-left: 120px;
        width: 560px !important; */
        margin-left: -8px;
        width: 290px !important;
        margin-top:-130px;
        font-size: 12px;
    }

    .modal-bus-body {
        margin-left: 0px;
        width: 800px !important;
    }

    .modal li > .pull-right {
        position: absolute;
        left: 120px;
        float: initial !important;
    }
}

.modal hr {
    margin-left: -15px;
    margin-right: -15px;
}

.modal-footer {
    border-top: none;
    padding-right: 30px;
    padding-bottom: 24px;
}

/*btn*/
.btn-sm > .icon {
    font-size: 15px;
    line-height: 1;
}

.modal-footer .btn + .btn {
    border: none
}

/*form*/

input.form-control {
    border-top: none;
    border-right: none;
    border-left: none;
    border-radius: inherit;
    box-shadow: none !important;
    padding-left: 1px;
    /*padding-top: 3px;*/
    margin-top: -5px;
    height: 28px;
}

.checkbox label, .radio label {
    margin-top: 0px;
}

/*list group*/

.list-group-item {
    border-left: none;
    border-right: none;
}

.list-group-condensed > .list-group-item {
    border: none;
    padding: 5px 10px 5px 10px;
    background-color: transparent;
}

.list-group-item span > .badge, .list-group-item span > p {
    line-height: 1.1;
    margin-top: 12px;
    margin-bottom: 0px;
    margin-right: 5px;
}

.list-group-item a.btn {
    position: relative;
    margin-top: -6px;
    box-shadow: none;
    padding-left: 4px;
    padding-right: 4px;
}

.list-group-modal h5,
.list-group-modal h6,
.list-group-modal p {
    margin-bottom: 0px;
}

.list-group-modal > .list-group-item:first-child {
    border-top: none;
}

/*card*/
.panel {
    overflow: hidden;
}

.list-group-item.btn {
    text-align: left !important;
}

.table th,.table td{
    word-break: break-all
}
.table > thead > tr > th {
    border-bottom: 1px solid #ddd
}

.table-condensed > tbody > tr > td,
.table-condensed > tbody > tr > th,
.table-condensed > tfoot > tr > td,
.table-condensed > tfoot > tr > th,
.table-condensed > thead > tr > td,
.table-condensed > thead > tr > th {
    padding: 11px 5px;
}

.table-hover > tbody > tr:hover > td {
    color: #555
}

/* bs-toggle cover */
.toggle {
    position: relative;
    display: inline-block;
}

.toggle.btn {
    min-height: 15px;
    min-width: 15px;
    height: 20px !important;
    width: 32px !important;
    border-radius: 100px;
}

.toggle.btn.btn-default, .toggle.btn.btn-primary {
    background-color: transparent !important;
    border: solid 2px #ddd;
}

.toggle-group {
    transition-duration: .15s;
    -webkit-transition-duration: .15s;
    padding: 0;
}

.toggle-on.btn {
    background-color: #41ba63
}

.toggle-on, .toggle-off {
    color: transparent !important;
    padding: 0;
    box-shadow: none !important;
    line-height: 1;
}

.toggle-handle {
    top: 0;
    border-radius: 100px;
    vertical-align: top;
    width: 32px;
    height: 16px;
    border: none
}

.toggle-handle:hover {
    background-color: white
}

/*ng-table cover*/
.ng-table th {
    text-align: left;
}

.ng-table-pagination {
    float: right;
}

/* hybrid */

.box > .panel {
    margin-bottom: 0;
    transition-duration: .15s;
}

.box > .panel:hover {
    -webkit-box-shadow: 0px 1px 3px 1px rgba(0, 0, 0, .15);
    box-shadow: 0px 1px 3px 1px rgba(0, 0, 0, .15)
}

.form-thumbnail-progress {
    position: absolute;
    bottom: 5px;
    left: 7px;
    right: 7px;
    margin-bottom: 0px;
    height: 7px;
    box-shadow: 0 -2px 9px 0px rgba(0, 0, 0, .35)
}

.form-thumbnail-progress > .progress-bar {
    -webkit-box-shadow: none;
    box-shadow: none;
}

.form-thumbnail-delete {
    position: absolute;
    right: -5px;
    top: -5px;
    width: 21px;
    height: 21px;
    border-radius: 14px;
    background-color: #d9534f;
    color: white;
    text-align: center;
    line-height: 21px;
    font-size: 12px;
    z-index: 1;
}

.form-thumbnail-delete:hover {
    background-color: #c9302c
}

.font-container, /*lengxue*/
.restart-container, /*lengxue*/
.procon-sub {
    display: -ms-flexbox;
    display: -webkit-box;
    display: -webkit-flex;
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: flex-start;
    margin: 2em;
}

.restart-container, /*lengxue*/
.procon-sub {
    margin-top: 5em;
}

/*lengxue*/
.font-item,
.restart-item {
    -webkit-box-flex: 1;
    -webkit-flex: 1;
    -ms-flex: 1;
    flex: 1;
    display: flex;
}

.font-upload-container,
.restart-time {
    display: flex;
    justify-content: flex-start;
}

.font-upload {
    position: absolute;
    top: -2px;
    left: -2px;
    width: 80px;
    height: 80px;
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;
}

/*lengxue*/
/*private*/
.nav-left {
    position: fixed;

}

.nav-left-content {
    position: absolute;
    left: -180px;
    width: 180px;
    min-height: 1px;
    max-height: calc(100vh - 250px); /*lengxue*/
    overflow: auto; /*lengxue*/
    padding-top: 19px;
    padding-right: 15px;
    padding-left: 15px;
}

.nav-left-content a {
    color: #818181;
    /*line-height:2;*/
}

.nav-left-content a > small {
    opacity: 0.5
}

.content-right {
    position: relative;
    left: 0;
    width: 100%;
    height: 100%;
    min-height: 1px;
    padding-right: 15px;
    padding-left: 15px;
}

.search-bar {
    margin-bottom: 10px;
}

.search-bar > .form-group {
    display: inline-block;
    width: 140px;
}

.search-bar > .button {
    top: -9px
}

.search-bar select {
    position: relative;
    top: -2px;
    width: 100%;
    border: none;
    border-bottom: 1px solid #ccc;
    border-radius: 0px;
    box-shadow: none;
}

.tag {
    cursor: pointer;
    border-radius: 12px;
    background-color: #ddd;
    line-height: 24px;
    font-size: 14px;
    padding: 0px 10px;
    margin-right: 2px;
    margin-bottom: 1px;
    margin-top: 2px;
    display: inline-block;
}

.tag.active {
    background-color: #666;
    color: white
}

/*用于表、表单里面的缩略图*/
.form-thumbnail {
    position: relative;
    margin-right: 5px;
    float: left;
    display: inline-block;
    width: 80px;
    height: 80px;
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;
    background-color: #ddd;
    border-radius: 3px;
    margin-right: 9px;
}

.form-thumbnail:after {
    content: ' ';
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    box-shadow: inset 0px 0px 3px 1px rgba(0, 0, 0, .05);
    pointer-events: none
}

label + hr {
    margin-top: 0;
    margin-bottom: 5px;
}

.form-thumbnail-creat {
    border: 2px dashed #C8C8C8;
    background-color: transparent;
    text-align: center;
    line-height: 110px;
    font-size: 12px;
    color: #797979;
    cursor: pointer;
}

.form-thumbnail-creat:after {
    display: none;
}

.form-thumbnail > video {
    position: absolute;
    width: 100%;
    height: 100%;
}

.form-thumbnail.form-thumbnail-screen-sm {
    width: 160px;
    height: 90px;
}

.form-thumbnail.form-thumbnail-screen-lg {
    width: 100%;
    height: 0;
    padding-bottom: 56.25%;
}

.form-thumbnail-sm {
    width: 40px;
    height: 40px;
}

/*针对图片上传控件*/
.col-xs-12 > .form-thumbnail {
    margin-top: 5px;
    margin-bottom: 5px;
}

/*设置组*/
.setting-group {
    padding: 0;
    padding-top: 7px;
}

.setting-group-item {
    position: relative;
    list-style: none;
    display: block;
    margin-bottom: 12px;
}

.setting-group-item > .row {
    margin-top: -10px
}

.setting-group-item .form-control {
    /*margin-top: 0px*/
}

.weeklysetting > .form-group {
    display: inline-block;
    width: 116px
}

.setting-group-delete {
    padding: 0 !important;
    text-align: left !important;
    font-size: 24px !important;
    top: 7px;
}

/*瀑布流和卡片式设计*/
.box {
    position: relative;
    width: 25%;
    /*width: 25%;*/
    display: inline-block;
    padding: 6px 5px;
    vertical-align: top
}

.box-corner-btn {
    position: absolute !important;
    top: 10px;
    right: 17px;
    color: white;
    font-size: 25px;
    cursor: pointer
}

.box-top-img {
    position: relative;
    padding-bottom: 56.25%;
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
}

.box-top-img:after {
    content: ' ';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, .05);
    border-radius: 4px 4px 0px 0px;
    transition-duration: 0.5s;
}

.box:hover .box-top-img:after {
    background-color: rgba(0, 0, 0, .35);
}

.box .list-group-item > span.pull-right {
    max-width: 72%;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}

@media (max-width: 1200px) {
    .box {
        width: 33%;
    }
}

@media (max-width: 992px) {
    .box {
        width: 50%;
    }
}

@media (max-width: 768px) {
    .box {
        width: 100%;
    }
}

/* waterfall */
.wf-container:before, .wf-container:after {
    content: '';
    display: table;
}

.wf-container:after {
    clear: both;
}

.wf-box {
}

.wf-column {
    float: left;
}

/* util */
.flex-hr-eq {
    display: flex;

}

.flex-item {
    flex-grow: 1;
    text-align: center;
}

.input-indent {
    text-indent: 18px;
}

.no-margin {
    margin: 0 !important;
}

.text-right {
    text-align: right;
}

.padding-bottom-72 {
    padding-bottom: 72px
}

.transparent {
    opacity: 0
}

.cursor-pointer {
    cursor: pointer;
}

.col-condensed {
    padding-left: 2px;
    padding-right: 2px;
}

.col-condensed:nth-of-type(1) {
    padding-left: 15px;
}

.col-condensed:nth-last-of-type(1) {
    padding-right: 15px;
}

/*ionic*/
.form-thumbnail-creat > .icon {
    position: absolute;
    left: 0;
    font-size: 32px;
    width: 100%;
    line-height: 59px;
    text-align: center;
}

.button.button-light {
    background-color: transparent !important;
}

/*blur*/
@supports (-webkit-filter:blur(12px)) {

    .bg:before {
        content: ' ';
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-image: inherit;
        background-size: cover;
        background-position: center center;
        background-repeat: no-repeat;

        transition-duration: .3s;
        -webkit-filter: blur(9px);
        filter: blur(9px);

        opacity: 1;

        /*will-change:opacity;
        transition-duration: 0.5s;*/
    }

    .app-title-blur-bg {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        height: 87px;
        opacity: 0;
        transition-duration: .1s
    }

    .app-title-blur-bg.in {
        opacity: 1
    }

    .app-view-blur-bg {
        position: absolute;
        top: 84px;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: white;
        opacity: 0;
        transition-duration: .1s
    }

    .app-view-blur-bg:before {
        content: ' ';
        position: absolute;
        top: -21px;
        left: -16px;
        width: 36px;
        height: 75px;
        background: white;
        -webkit-filter: blur(9px);
        filter: blur(9px);
    }

    .app-view-blur-bg:after {
        content: ' ';
        position: absolute;
        top: -21px;
        right: -16px;
        width: 48px;
        height: 75px;
        background: white;
        -webkit-filter: blur(9px);
        filter: blur(9px);
    }

    .app-view-blur-bg.in {
        opacity: 1
    }

    .blur {
        -webkit-filter: blur(12px);
        filter: blur(12px)
    }
}

/*开票状态*/
.bill-status {
    margin-top: -10px;
    margin-bottom: 10px;
}

.bill-status label {
    font-weight: normal !important;
    display: inline-block;
    margin-right: 20px;
    cursor: pointer;
}

.bill-status input {
    vertical-align: text-top;
    height: 13px;
}

.nav-tabs > li {
    user-select: none;
}

.form-control[readonly] {
    background-color: transparent !important;
    opacity: 1;
}


/*--------------------分步操作--------------------------*/
.stepwizard-step p {
    margin-top: 10px;
}
.stepwizard-row {
    display: table-row;
}
.stepwizard {
    display: table;
    width: 100%;
    position: relative;
    margin: 0 auto;
}
.stepwizard-step button[disabled] {
    opacity: 1 !important;
    filter: alpha(opacity=100) !important;
}
.stepwizard-row:before {
    top: 14px;
    bottom: 0;
    position: absolute;
    content: " ";
    width: 100%;
    height: 1px;
    background-color: #ccc;
    z-order: 0;
}
.stepwizard-step {
    display: table-cell;
    text-align: center;
    position: relative;
}
.btn-circle {
    width: 30px;
    height: 30px;
    text-align: center;
    padding: 6px 0;
    font-size: 12px;
    line-height: 1.428571429;
    border-radius: 15px;
}

.step-content{
    margin: 0 auto;
}
.step-content .step-head{
    margin: 20px 0;
    position: relative
}
.auth-content .side-control{
    position: absolute;right: 20px
}

.auth-table.table>tbody>tr.selected>td{
    background: #337ab7;
    color: #ffffff !important;
}

.overflowAuto{
    overflow-y: auto;
}
.btn-gn{
    border-radius: 5px;
    margin:0 5px;
}
input[type=checkbox]:focus{
    outline: none 0;
}
/* .btnCenter{
    text-align: center;
} */
.setting{
    width: 100%;
    height: 30px;
    line-height: 30px;
    font-weight: 700;
    font-size: 14px;
    color: #666;
    margin:20px 0;
}
.tableMessage{
    overflow-y: auto;
    height: 300px;
}
.tableMessageLists{
    width: 100%;
    list-style: none;
    display: flex;
    justify-content: center;
    align-items: center;
    margin:5px 0;
    padding:0;
    border-bottom: 1px solid #ddd;
}
.tableMessageList{
   width: 25%;
   list-style: none;
   padding-bottom:5px;
   text-align: center;
}
.tableMessageList1{
    width: 30%;
}
.tableMessageList2{
    width: 20%;
}
.tableMessageList3{
    width:15%;
}
.tableMessageList4{
    width:10%;
}
.tableMessageContent{
    overflow: hidden;
    text-overflow:ellipsis;
    white-space: nowrap;
}
.tableMessageHead{
    color: #2a6cc7;
}
/* table a,table a:hover{
    color: #337ab7!important;
} */
.SingleOmission{
    max-width: 200px;
}
/* .btn-messg{
    color:#c1272d!important;
} */

/* 影音房 */
.movieRoom .date-wrap{
    height: 40px;overflow: hidden; padding-top: 8px;
}

.movieRoom .dot-tag{
    width: 8px;height: 8px; background: #999; border-radius: 50%;display: inline-block; vertical-align: middle; box-shadow: #999 0px 0px 10px; margin-left: 10px; position: absolute; right: 20px; top: 20px
}
.movieRoom .dot-tag.online{
    background:#449d44;box-shadow: #449d44 0px 0px 10px;
}

.movieRoom .btn-wrap{
    text-align: right;
}

/* 资源库 */
.material-wrap .table>thead>tr>th,.material-wrap .table>tbody>tr>td{
    border: 0
}
.material-wrap .modal-body:after{
    content: '';display: block; clear: both;
}
.material-item{
    display: inline-block; margin: 0 10px; border: 1px solid #ddd; margin-bottom:10px;width: 109px;
}
.material-item .name{
    line-height: 30px;padding: 0 20px; border-top: 1px solid #ddd;
}
.material-item .name span{
    text-overflow: ellipsis;white-space: nowrap; overflow: hidden; display: block;
}
.material-item .checked,.material-item .icon{
    display: none
}
.material-item.selected .checked{
    background: rgba(0,0,0,0.5); position: absolute; width: 100%; height: 100%;display: block;
}
.material-item.selected .icon{
    position: absolute; color: #fff;display: block; font-size: 40px; left: 40px;top: 20px;
}
.material-cover{
    background:url(../imgs/unknown.png) no-repeat center center;background-size:cover; height: 100px;position: relative;
}

.tools{
    left: 0px;
    position: absolute;
    width: 180px;
    max-height: calc(100vh - 150px);
    padding-right: 15px;
    padding-left: 15px;
    overflow: auto;
    margin-top: 24px;
}

.selectbar{
    height: 45px;
    font-size: 17px;
    width: 100%;
    font-weight: 500;
    color: #212121;
    border: 2px solid #0cacda;
    border-radius: 5px;
}
.nav-left-group{
    position: absolute;
    left: -180px;
    width: 190px;
    overflow: auto;
    padding-top: 0px;
    padding-right: 15px;
    padding-left: 0px;
}

.white-text {
    color: white;
}

/*
* Group Message style
*/
.groupMessageList .row {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.groupMessageList table {
    font-size: 1em;
    text-align: center;
}

.groupMessageList table img {
    width: 64px;
    height: 64px;
    display: block;
    margin: auto;
}

.groupMessageList table>thead>tr>th {
    text-align: center;
}

.groupMessageList table>tbody>tr>td {
    vertical-align: middle;
}

.groupMessageList .fix-width-50 {
    width: 100px;
}

.groupMessageList .fix-width-100 {
    width: 100px;
}

.groupMessageList .fix-width-150 {
    width: 150px;
}

.groupMessageList .fix-width-250 {
    width: 255px;
}

.groupMessageList table .status-block {
    border-radius: 12px;
    text-align: center;
    font-size: 12px;
    padding: 5px;
}

/*
* edit message modal style
*/

.modal-content {
    border-radius: 12px;
}

.editGroupMessageModal {
    display: flex;
    flex-direction: column;
    padding: 40px 50px;
}

.editGroupMessageModal .header {
    font-size: 24px;
    margin-bottom: 1em;
}

.editGroupMessageModal .body {
    margin-bottom: 1em;
}

.editGroupMessageModal .body input[disabled]{
    background: none;
}

.editGroupMessageModal .body .btn-group.expand {
    width: 100%;
}

.editGroupMessageModal .body .btn-group[uib-dropdown] .btn {
    width: 100%;
    box-shadow: none;
    border-bottom: 1px solid #cccccc;
    border-radius: 0;
    text-align: left;
    padding: 0;
}

.editGroupMessageModal .body .calendar-icon {
    border: none;
}

.editGroupMessageModal .body .btn-group ul {
    width: 100%;
}

.editGroupMessageModal .body .btn-group .caret {
    position: absolute;
    right: 0;
    top: 50%;
}

.editGroupMessageModal .body .form-group {
    margin-bottom: 2em;
}

.editGroupMessageModal .footer {

}

.form-group.pic-uploader .form-thumbnail img {
    height: 100%;
    width: 100%;
    display: block;
    margin: auto;
}

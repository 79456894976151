/*  This is the styles for terminal pages, going from top to bottom as
 *  the page is.
 */


 /* *****************
 * left content style
 * ******************
 */

 /* *****************
 * Top status styles
 * *****************
 */
.top-status {
    display: flex;
    flex-direction: row;
    align-items: baseline;
}

.top-status h4 {
    margin: 10px;
}

.top-status .search-bar {
    margin: 10px;
    position: relative;
}

.top-status .search-bar .clear {
    position: absolute;
    top: 0;
    right: 0;
    font-size: 12px;
}
/* *****************
 * Legend styles
 * *****************
 */
.legend {
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: wrap;
}

.legend .left {
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: flex-start;
    height: 33px;
    max-width: 100%;
}

.legend .right {
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: flex-end;
    height: 33px;
    max-width: 100%;
    /* use this to fill the rest space in flex box */
    flex: 1;
}

.legend .legend-block {
    width: 90px;
    height: 40px;
    margin-right: 12px;
    display: flex;
    align-items: center;
    padding: 0;
    border-radius: 0;
}

.legend-block.legend-vertical-break {
    border-left: 1px solid black;
    padding-left: 10px;
}

.legend .legend-block.active {
    border-bottom: 1px solid #26A69A;
    box-shadow: none;
}

.legend-block .circle-block {
    width: 20px;
    height: 20px;
    border-radius: 10px;
    margin-right: 5px;
    display: inline-block;
}

.legend-block span {
    font-size: 14px;
    display: inline-block;
}

.margin-right-10 {
    margin-right: 10px;
}

.right .btn:not(.circle-button) {
    color: white;
    font-size: 12px;
    padding: 5px;
}

.circle-button.anchor {
    position: absolute;
    right: 0;
}

.circle-button {
    color: white;
    width: 30px;
    height: 30px;
    margin: 0 20px;
    border-radius: 15px;
    box-shadow: 0px 0px 0px 2px #c9c9c9;
    border: 1px solid white;
    display: flex;
    align-items: center;
    justify-content: center;
}

/* *****************
 * list styles
 * *****************
 */

.groupTypeList {
    display: flex;
    flex-direction: column;
}

.groupTypeList .list-item {
    display: flex;
    flex: 1;
    flex-direction: row;
    margin: 5px;
    align-items: center;
    border-bottom: 1px solid rgba(0, 0, 0, 0);
}

.groupTypeList .list-item:hover {
    cursor: pointer;
    background-color: #e7e7e7;
}

.groupTypeList .list-item > li {
    flex: 9;
}

.groupTypeList .list-item a:hover {
    text-decoration: none;
}

.groupTypeList .list-item.active {
    border-bottom: 1px solid #2196F3;
}

.groupTypeList .list-item > li.active {
    /* border-bottom: 1px solid #2196F3; */
}

.groupTypeList .delete-button {
    flex: 1;
    font-size: 14px;
    width: auto;
    height: 100%;
    border: 1px solid rgba(0,0,0,0);
}
.groupTypeList .delete-button:hover {
    animation: shake 1.5s cubic-bezier(.36,.07,.19,.97) both;
    transform: translate3d(0, 0, 0);
    backface-visibility: hidden;
    perspective: 1000px;
    animation-iteration-count: infinite;
}

@keyframes shake {
  10%, 90% {
    transform: translate3d(-1px, 0, 0);
  }

  20%, 80% {
    transform: translate3d(1px, 0, 0);
  }

  30%, 50%, 70% {
    transform: translate3d(-1px, 0, 0);
  }

  40%, 60% {
    transform: translate3d(1px, 0, 0);
  }
}

/* *****************
 * Card block styles
 * *****************
 */
.sticky {
    position: sticky;
    top: -48px;
    background: white;
}
.pad-10 {
    padding: 10px;
}
.fixed-max-height {
    max-height: 550px;
    overflow: scroll;
}
.fixed-min-height {
    min-height: 82px;
}
.flex-row {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}

.flex-row .last-row {
    position: absolute;
    bottom: 0;
    right: 16px;
}

.block-margin {
    margin-right: 12px;
    margin-bottom: 12px;
}
.card-block {
    border-radius: 5px;
    border-top-left-radius: 0px;
    font-size: 14px;
    width: 66px;
    padding: 0px;
    border: 1px solid #9d9d9d;
    box-shadow: 3px 3px 1.5px 0px #b1b1b1;
    transition: 0.2s;
    display: flex;
    align-items: center;
    justify-content: center;
}

.card-block:not(.active){
    background-color: white;
}

.card-block:hover {
    /* transform: scale(1.1); */
}

.card-block.active {
    color: white;
}

.card-block .status {
    border-top: 22px solid transparent;
    border-left: 12px solid transparent;
    border-right: 13px solid transparent;
    border-bottom: 20px solid white;

    transition: border-width, 0.2s;
}

.card-block.active .status {
    border-left: 0px solid transparent;
    border-right: 0px solid transparent;
}

.card-block span {
    flex: 2;
    overflow: hidden;
}

/* *****************
 * mask style
 * *****************
 */


 /* *****************
  * popover style
  * *****************
  */

.cardPopOver {
    padding: 0 10px;
    display: flex;
    flex-direction: column;
    font-size: 11px;
}

.cardPopOver .row {
    flex: 1;
    display: flex;
    flex-direction: row;
}

.cardPopOver .row .flex-50 {
    flex: 50;
}

.cardPopOver .row span {
    width: 50px;
}

.cardPopOver .btn {
    font-size: 12px;
    line-height: 12px;
}

/* *****************
 * pushModal style
 * *****************
 */

.modal-content {
    border-radius: 12px;
}
.modal-content .display-label{
    display: flex;
}

.modal-content .display-label span{
    flex: 1;
}

.pushNewMessageModal {
    display: flex;
    flex-direction: column;
    padding: 40px 50px;
}

.pushNewMessageModal .header {
    font-size: 24px;
    margin-bottom: 1em;
}

.pushNewMessageModal .body {
    margin-bottom: 1em;
}

.pushNewMessageModal .body input[disabled]{
    background: none;
}

.pushNewMessageModal .body .btn-group.expand {
    width: 100%;
}

.pushNewMessageModal .body .btn-group[uib-dropdown] .btn {
    width: 100%;
    box-shadow: none;
    border-bottom: 1px solid #cccccc;
    border-radius: 0;
    text-align: left;
    padding: 0;
}

.pushNewMessageModal .body .calendar-icon {
    border: none;
}

.pushNewMessageModal .body .btn-group ul {
    width: 100%;
}

.pushNewMessageModal .body .btn-group .caret {
    position: absolute;
    right: 0;
    top: 50%;
}

.pushNewMessageModal .body .form-group {
    margin-bottom: 2em;
}

.pushNewMessageModal .footer {

}

.form-group.pic-uploader .form-thumbnail img {
    height: 100%;
    width: 100%;
    display: block;
    margin: auto;
}
/* *****************
 * float window styles
 * *****************
 */
.float-window {
    position: fixed;
    right: 0;
    bottom: 0;
    width: 10%;
    height: 35%;
    display: flex;
    flex-direction: column;
    overflow: scroll;
}

.float-window .element {
    font-size: 12px;
    padding: 3px;
}

.float-window .element:hover {
    cursor: pointer;
    color: #FF1744;
    background-color: #f4f4f4;
}
